import React, { useEffect, useState } from 'react';
import { useWindowContext } from '../context/WindowContext';
import { motion } from 'framer-motion';
import { ReactTyped } from 'react-typed';

const Shutdown = () => {
  const {     setShuttingDown, shuttingDown } = useWindowContext();

    // State to manage the shutdown message
    const [shutdownMessages, setShutdownMessages] = useState([
        'Shutting Down...',
        'Shutdown Failed',
      ]);
      const [messageComplete, setMessageComplete] = useState(false);
    
      useEffect(() => {
        if (shuttingDown) {
          // Update message dynamically if needed
          setShutdownMessages(['Shutting Down...', 'ERROR: SHUTDOWN FAILED']);
        }
      }, [shuttingDown]);
    

    // Listen for any key press to change shuttingDown to false
    useEffect(() => {
        const handleKeyPress = () => {
          // Only allow changing shuttingDown to false if messageComplete is true
          if (messageComplete) {
            setShuttingDown(false);  // Set shuttingDown to false on key press
            setMessageComplete(false);  // Reset messageComplete to false
          }
        };
    
        // Attach the keydown event listener
        window.addEventListener('keydown', handleKeyPress);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, [messageComplete, setShuttingDown]);


  return (
    <motion.div
            className="fixed inset-0 bg-black/90 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.div
              className="text-white text-center text-2xl font-bold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              {/* React Typed for dynamic typing */}
              <ReactTyped
                strings={shutdownMessages}
                typeSpeed={100}
                backSpeed={20}
                backDelay={5000}
                loop={false} // Stop after one loop
                onComplete={() => setMessageComplete(true)} // Mark message as complete
              />
              {messageComplete && (
                <div className="mt-4 text-lg text-white">
                  <ReactTyped strings={['Press any key to exit...']} typeSpeed={100} loop={false} />
                </div>
              )}
            </motion.div>
          </motion.div>
  );
};

export default Shutdown;