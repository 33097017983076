import { createContext, useState, useContext, useRef } from 'react';

export const WindowContext = createContext();

const WindowProvider = ({ children }) => {
  const [isSocialVisible, setIsSocialVisible] = useState(false);
  const [isWalletCheckerVisible, setIsWalletCheckerVisible] = useState(false);
  const [isAIVisible, setIsAIVisible] = useState(true);
  const [isWhitepaperVisible, setIsWhitepaperVisible] = useState(true);
  const [isXVisible, setIsXVisible] = useState(true);

  const toggleSocial = () => setIsSocialVisible(!isSocialVisible);
  const toggleWalletChecker = () => setIsWalletCheckerVisible(!isWalletCheckerVisible);
  const toggleAI = () => setIsAIVisible(!isAIVisible);
  const toggleWhitepaper = () => setIsWhitepaperVisible(!isWhitepaperVisible);
  const toggleXBrowser = () => setIsXVisible(!isXVisible);
  const [shuttingDown, setShuttingDown] = useState(false);

  const socialRef = useRef(null);
  const walletCheckerRef = useRef(null);
  const terminalRef = useRef(null);
  const AIRef = useRef(null);
  const whitepaperRef = useRef(null);
  const XRef = useRef(null);

  return (
    <WindowContext.Provider
      value={{
        isSocialVisible,
        isWalletCheckerVisible,
        toggleSocial,
        toggleWalletChecker,
        setIsSocialVisible,
        setIsWalletCheckerVisible,
        socialRef,
        walletCheckerRef,
        shuttingDown, 
        setShuttingDown, terminalRef,
        isAIVisible, setIsAIVisible,
        AIRef,
        toggleAI,
        isWhitepaperVisible, setIsWhitepaperVisible,
        whitepaperRef,
        toggleWhitepaper,
        isXVisible, setIsXVisible,
        toggleXBrowser, XRef
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export const useWindowContext = () => {
  const context = useContext(WindowContext);
  if (!context) {
    throw new Error('useWindowContext must be used within a WindowProvider');
  }
  return context;
};

export default WindowProvider;

