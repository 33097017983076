import React, { useState, useEffect } from 'react';
import { FaWallet, FaWifi, FaPowerOff, FaDev, FaRobot, FaBars, FaFileCode, FaGlobeAmericas } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useWindowContext } from '../context/WindowContext';

const Taskbar = () => {
  const { toggleSocial, toggleWalletChecker, toggleAI, toggleWhitepaper, toggleXBrowser, setShuttingDown } = useWindowContext();

  // State for current date and time
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showShutdownDialog, setShowShutdownDialog] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Update the time every second
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Format the date and time
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const formattedDate = currentTime.toLocaleDateString();

  return (
    <>
      {/* Taskbar */}
      <nav className="bg-gradient-to-r from-blue-700/10 via-indigo-500/10 to-violet-500/10 text-white fixed bottom-0 left-0 right-0 border-indigo-600 border-t">
        {/* Hamburger Menu for Mobile */}
        <div className="flex items-center justify-between p-2 sm:hidden">
          <button
            className="text-xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FaBars />
          </button>
          <div className="text-xs text-right">
            <p>{formattedDate}</p>
            <p>{formattedTime}</p>
          </div>
        </div>

        {/* Menu Items */}
        <div
          className={`flex-col items-center space-y-4 sm:flex sm:flex-row sm:space-y-0 sm:space-x-4 p-2 transition-all duration-300 sm:justify-between ${
            isMenuOpen ? 'flex' : 'hidden'
          } sm:flex`}
        >
          {/* Left Side: System Icon */}
          <div className="flex items-center justify-center space-x-4">
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-red-500"
              onClick={() => setShowShutdownDialog(true)}
            >
              <FaPowerOff className="text-xl" />
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => toggleWalletChecker()}
            >
              <FaWallet className="text-xl" />
              <span className="text-xs">Sol Wallet</span>
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => toggleSocial()}
            >
              <FaGlobeAmericas className="text-xl" />
              <span className="text-xs">Socials</span>
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => toggleXBrowser()}
            >
              <FaXTwitter className="text-xl" />
              <span className="text-xs">X</span>
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => alert('403 Forbidden.')}
            >
              <FaDev className="text-xl" />
              <span className="text-xs">Terminal</span>
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => toggleAI()}
            >
              <FaRobot className="text-xl" />
              <span className="text-xs">Agent</span>
            </button>
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
              onClick={() => toggleWhitepaper()}
            >
              <FaFileCode className="text-xl" />
              <span className="text-xs">Whitepaper</span>
            </button>
          </div>

          {/* Right Side: Wi-Fi and Date/Time */}
          <div className="flex items-center space-x-4 pr-4 mr-4">
            {/* Wi-Fi Icon with Tooltip */}
            <div className="relative group flex items-center">
              <div className="relative flex items-center">
                <FaWifi className="text-xl" />
                <span className="absolute top-0 right-0 h-2 w-2 bg-yellow-500 rounded-full border border-white" />
              </div>
              {/* Tooltip */}
              <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Wi-Fi Connected
              </div>
            </div>
            <div className="text-xs text-right hidden sm:block">
              <p>{formattedDate}</p>
              <p>{formattedTime}</p>
            </div>
          </div>
        </div>
      </nav>

      {/* Shutdown Confirmation Dialog */}
      {showShutdownDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
          <div className="bg-gray-800 text-white rounded-lg shadow-lg p-6 w-80">
            <h2 className="text-lg font-bold mb-4">Shutdown System</h2>
            <p className="mb-6 text-sm">Are you sure you want to shut down?</p>
            <div className="flex justify-between">
              <button
                className="bg-red-600 hover:bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={() => {
                  // Handle shutdown logic
                  setShuttingDown(true);
                  setShowShutdownDialog(false);
                }}
              >
                Yes
              </button>
              <button
                className="bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded-md"
                onClick={() => setShowShutdownDialog(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Taskbar;


