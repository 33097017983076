import React  from 'react';
import './styles/css/App.css';
import Main from './pages/main';
import WindowProvider from './context/WindowContext';
import APIProvider from './context/APIContext';

function App() {
  return (
    <APIProvider>
    <WindowProvider>
    <Main/>
    </WindowProvider>
    </APIProvider>
  );
}

export default App;
