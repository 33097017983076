import React, { useState, useEffect } from 'react';
import { useApiContext } from '../context/APIContext';
import Draggable from 'react-draggable';
import { ReactTyped } from 'react-typed';
import { XMarkIcon, MinusIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useWindowContext } from '../context/WindowContext';
import 'tailwindcss/tailwind.css';

const AI = () => {
  const [question, setQuestion] = useState('');
  const { loading, error, response, askAI } = useApiContext();
  const { AIRef, isAIVisible, toggleAI } = useWindowContext();
  const [chevronVisible, setChevronVisible] = useState(true);

  const handleAskAI = async () => {
    await askAI(question);
  };

  useEffect(() => {
    // Set the chevron to fade in and out every 2 seconds
    const interval = setInterval(() => {
      setChevronVisible((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  if (!isAIVisible) return null;

  return (
    <Draggable handle=".draggable-header" nodeRef={AIRef}>
      <div ref={AIRef} className=" bg-black text-white rounded-xl m-5 cursor-move border border-indigo-800 w-screen max-w-lg ">
        {/* Header Section for draggable and close/minimize buttons */}
        <div className="draggable-header flex items-center justify-between bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-2 border-b border-indigo-800 rounded-t-xl">
          <h1 className="text-xl font-bold">ai-terminal.exe</h1>
          <div className="flex space-x-2">
            <button
              className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleAI()}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
            <button
              className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleAI()}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Command Prompt Input & Response Section */}
        <div className="p-4 space-y-4 overflow-y-auto flex flex-col h-full">
          {/* Input styled to look like command prompt */}
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Type your command"
              className="bg-gray-900 text-white font-mono p-2 w-full border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Ask AI Button */}
          <button
            onClick={handleAskAI}
            disabled={loading}
            className="bg-gray-800 text-white p-2 rounded-md w-full font-mono mt-2"
          >
            {loading ? 'Loading...' : 'Send Command'}
          </button>

          {/* Error and Response */}
          {error && <p className="text-red-500">{error}</p>}

          {/* Response Container with Scrollable Area */}
          <div className="mt-4 flex-grow border-t border-gray-800  overflow-y-auto  p-4">
            <div className="flex items-center space-x-2">
              <span className="text-green-500 font-mono">
                <ChevronRightIcon className={`w-4 h-4 transition-opacity duration-500 ${chevronVisible ? 'opacity-100' : 'opacity-0'}`} />
              </span>
              <div className="w-full flex justify-left items-left">
                {response ? (
                  <p className="text-white font-mono">
                    <ReactTyped
                      strings={Array.isArray(response) ? response : [response]}
                      typeSpeed={30}
                    />
                  </p>
                ) : (
                  <p className="text-white font-mono">
                    <ReactTyped
                      strings={['Awaiting response...']}
                      typeSpeed={30}
                    />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default AI;

