import React, { useState } from 'react';
import { ReactTyped } from 'react-typed';
import { XMarkIcon, MinusIcon, CpuChipIcon } from '@heroicons/react/24/solid'
import { useWindowContext } from '../context/WindowContext';
import Draggable from 'react-draggable';

const Logo = () => {
  const [isTitleTyped, setIsTitleTyped] = useState(false);

  const { terminalRef} = useWindowContext();


  return (
    <Draggable nodeRef={terminalRef} handle=".draggable-header">
    <div id="logo" ref={terminalRef} className=' bg-black border border-indigo-800 rounded-xl'>
        <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-indigo-600">
        <h1 className="text-xl text-white font-bold"><span className="inline-block align-middle"><CpuChipIcon className='w-6 h-6'/></span><span className="inline-block align-middle">app.exe</span></h1>
        <div className="flex space-x-2">
          <button className=" text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"  onClick={() =>  alert('403 Forbidden.')} >
          <MinusIcon className='w-4 h-4'/>
          </button>
          <button className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"  onClick={() =>   alert('500 Internal Server Error: \nApp cannot be shutdown')} >
          <XMarkIcon className='w-4 h-4'/>
          </button>
        </div>
      </div>
      <h1 className="font-bold text-3xl sm:text-5xl md:text-6xl lg:text-7xl text-slate-300 p-4">
        <ReactTyped
          strings={["Gnosis.AI"]}
          showCursor={false}
          typeSpeed={200}
          onComplete={() => setIsTitleTyped(true)}
        />
      </h1>
      {isTitleTyped && (
        <h2 className="font-bold text-md text-slate-300 p-4">
          <ReactTyped
            strings={[
              "Gnosis is not just knowledge but the awakening of inner wisdom—a journey where heart and mind unite, revealing the profound truths that guide your soul toward its highest purpose."
            ]}
            typeSpeed={40}
          />
        </h2>
      )}
    </div>
    </Draggable>
  );
};

export default Logo;

