import { useEffect, useState } from 'react';
import axios from 'axios';
import { useWindowContext } from '../context/WindowContext';
import Draggable from 'react-draggable';
import { XMarkIcon, MinusIcon } from '@heroicons/react/24/solid';

const X = () => {
  const { toggleXBrowser, isXVisible, XRef } = useWindowContext();
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTweets = async () => {
      try {
        const response = await axios.get('https://gnosis-ai.cloud/tweets', {
          params: { username: 'GnosisAIO' }, // Replace with your handle
        });
        setTweets(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tweets:', error);
        setLoading(false);
      }
    };

    fetchTweets();
  }, []);

  if (!isXVisible) return null;

  return (
    <Draggable nodeRef={XRef} handle=".draggable-header">
      <div
        ref={XRef}
        className="border-indigo-600 border relative font-bold bg-black border-solid rounded-b-xl m-5 w-screen max-w-3xl cursor-move"
      >
        <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-gray-600">
          <h1 className="text-xl text-white font-bold">X.exe</h1>
          <div className="flex space-x-2">
            <button
              className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleXBrowser()}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
            <button
              className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleXBrowser()}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="p-4 text-white">
          {loading ? (
            <p>Loading tweets...</p>
          ) : tweets.length > 0 ? (
            <ul className="space-y-4">
              {tweets.map((tweet) => (
                <li
                  key={tweet.id}
                  className="bg-gray-800 p-3 rounded-lg shadow-md border border-gray-700"
                >
                  <p>{tweet.text}</p>
                  <small className="text-gray-400">
                    Posted on: {new Date(tweet.created_at).toLocaleString()}
                  </small>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tweets found.</p>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default X;
