import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import SolPrice from './SolTracker';
import Draggable from 'react-draggable';
import { useWindowContext } from '../context/WindowContext';
import { XMarkIcon, MinusIcon } from '@heroicons/react/24/solid'

// Replace with your QuickNode Solana RPC URL
const QUICKNODE_RPC_URL = process.env.REACT_APP_SOLANA_RPC_URL;

const WalletChecker = () => {
  const [solBalance, setSolBalance] = useState(0);
  const [tokenBalances, setTokenBalances] = useState(new Map());
  const [walletAddress, setWalletAddressInput] = useState('');

  const {toggleWalletChecker, isWalletCheckerVisible, walletCheckerRef } = useWindowContext();

  // Use QuickNode RPC URL
  const connection = new Connection(QUICKNODE_RPC_URL, 'confirmed');

  // Helper function to fetch SOL balance
  const fetchSolBalance = async () => {
    try {
      const publicKey = new PublicKey(walletAddress);
      const balance = await connection.getBalance(publicKey);
      setSolBalance(balance / 1e9); // Convert lamports to SOL, 1 SOL = 1e9 lamports
    } catch (error) {
      console.error('Error fetching SOL balance:', error);
    }
  };

  // Helper function to fetch SPL token balances
  const fetchTokenBalances = async () => {
    try {
      const publicKey = new PublicKey(walletAddress);
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      });

      const tokenBalancesMap = new Map();

      tokenAccounts.value.forEach(({ account }) => {
        const tokenAmount = account.data.parsed.info.tokenAmount;
        const tokenAddress = account.data.parsed.info.mint;
        
        // Only add tokens with a non-zero balance
        if (tokenAmount.uiAmount > 0) {
          tokenBalancesMap.set(tokenAddress, tokenAmount.uiAmount);
        }
      });

      setTokenBalances(tokenBalancesMap);
    } catch (error) {
      console.error('Error fetching token balances:', error);
    }
  };

  // Fetch both SOL and token balances when the component mounts
  useEffect(() => {
    fetchSolBalance();
    fetchTokenBalances();
  });

  if (!isWalletCheckerVisible) return null;

  return (
    <Draggable nodeRef={walletCheckerRef}>
      <div className="bg-black rounded-b-xl text-white overflow-auto border-indigo-800 border z-10" ref={walletCheckerRef} >
        <div className="drag-handle flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-indigo-600 cursor-move">
          <h1 className="text-xl font-bold">wallet-checker.exe</h1>
          <div className="flex space-x-2">
            <button className=" text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => toggleWalletChecker()}>
              <MinusIcon className='w-4 h-4'/>
            </button>
            <button className="text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => toggleWalletChecker()}>
              <XMarkIcon className='w-4 h-4'/>
            </button>
          </div>
        </div>

        <SolPrice />

        <div className="mb-4">
          <input
            type="text"
            value={walletAddress}
            onChange={(e) => {
              setWalletAddressInput(e.target.value);
              fetchSolBalance();
              fetchTokenBalances();
            }}
            placeholder="Enter wallet address"
            className="p-2 border border-gray-300 rounded text-black"
          />
          {walletAddress && (
            <div>
              <p className='p-4'>Wallet Address: {walletAddress}</p>
              <p>SOL Balance: {solBalance.toFixed(5)} SOL</p> {/* Display SOL balance with 5 decimals */}
            </div>
          )}
        </div>

        {tokenBalances.size > 0 && (
          <div>
            <h3 className="text-xl font-bold">Token Balances</h3>
            <ul>
              {Array.from(tokenBalances.entries()).map(([mint, balance]) => (
                <li key={mint}>
                  Token Mint: {mint}, Balance: {balance}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default WalletChecker;
