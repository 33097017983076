import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Socials from '../components/Socials';
import background from '../styles/images/background.gif';
import WalletChecker from '../components/WalletChecker';
import Logo from '../components/Logo';
import Taskbar from '../components/Taskbar';
import { useWindowContext } from '../context/WindowContext';
import Shutdown from '../components/Shutdown';
import AI from '../components/AI';
import Whitepaper from '../components/Whitepaper.js';
import X from '../components/X.js';

function Main() {
  const {
    socialRef,
    walletCheckerRef,
    terminalRef,
    shuttingDown,
    AIRef,
    whitepaperRef,
    xRef,
  } = useWindowContext();

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${background})`,
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
      }}
    >
      {/* Conditionally render the shutdown overlay */}
      <AnimatePresence>
        {shuttingDown && <Shutdown />}
      </AnimatePresence>

      {/* Main Content */}
      <motion.div
        className={`bg-black/90 h-full w-full flex flex-col items-center justify-between relative`}
        initial={{ opacity: 0.5 }}
        animate={{ opacity: [0.95, 1, 0.95] }}
        transition={{ duration: 3, repeat: Infinity, ease: 'linear' }}
      >
        {/* Logo Section */}
        <motion.div
          className="p-5 text-center rounded-3xl m-5 w-full sm:w-auto"
          ref={terminalRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Logo />
        </motion.div>

        {/* Socials Section */}
        <motion.div
          className="relative flex flex-col items-center justify-center space-y-4 p-4 sm:flex-row sm:space-y-0 sm:space-x-6 font-bold rounded-xl m-5 w-11/12 sm:w-3/4"
          ref={socialRef}
          initial={{ opacity: 0, scale: 0.8, y: 0 }}
          animate={{ opacity: 1, scale: 1, y: -30 }}
          transition={{ duration: 0.8, delay: 1 }}
        >
          <Socials />
        </motion.div>

        {/* Wallet Checker Section */}
        <div className="mb-10 w-full px-4 sm:max-w-md z-20 absolute top-1/3">
          <motion.div
            ref={walletCheckerRef}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1.5 }}
          >
            <WalletChecker />
          </motion.div>
        </div>

        {/* AI Question Section */}
        <motion.div
          className="relative flex flex-col items-center justify-center space-y-4 p-4 font-bold rounded-xl m-5 w-11/12 sm:w-1/4"
          ref={AIRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 2 }}
        >
          <AI />
        </motion.div>

        {/* Whitepaper Section */}
        <motion.div
          className="relative flex flex-col items-center justify-center space-y-4 p-4 font-bold rounded-xl m-5  sm:w-1/4"
          ref={whitepaperRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2, delay: 1 }}
        >
          <Whitepaper />
        </motion.div>

        {/* Taskbar Section */}
        <div className="w-full fixed bottom-0 bg-black/80 z-20">
          <Taskbar />
        </div>

        {/* X Section */}
        <motion.div
          className="fixed top-5 right-5"
          ref={xRef}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 1 }}
        >
          <X />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Main;

