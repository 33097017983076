import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Create a Context for the API
const ApiContext = createContext();

// Custom hook to use the API context
export const useApiContext = () => {
  return useContext(ApiContext);
};

// API Provider component
 const ApiProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState('');

  // Function to call the Netlify function that interacts with OpenAI
  const askAI = async (question) => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post('/ask-ai', {
        question,
      });
      setResponse(result.data.answer);
    } catch (err) {
      setError('Error fetching AI response.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ApiContext.Provider value={{ loading, error, response, askAI }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
