import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SolPrice = () => {
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to fetch the SOL price
    const fetchSolPrice = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
        );
        setPrice(response.data.solana.usd);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching SOL price:', error);
        setLoading(false);
      }
    };

    // Fetch price immediately
    fetchSolPrice();

    // Set up an interval to refresh the price
    const interval = setInterval(fetchSolPrice, 60000); // Refresh every 10 seconds

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-4 text-center text-white">
      {loading ? (
        <p>Loading SOL price...</p>
      ) : (
        <p>
          Current SOL Price: <span className="font-bold">${price?.toFixed(2)}</span>
        </p>
      )}
    </div>
  );
};

export default SolPrice;
